import { ViewportScroller } from '@angular/common';
import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MonographMedicalContent } from 'src/app/models/product.model';
import { HttpService } from 'src/app/services/http.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-monograph',
  templateUrl: './monograph.component.html',
  styleUrls: ['./monograph.component.css']
})
export class MonographComponent {
  monograph:MonographMedicalContent;
  constructor(private service: HttpService, private route: ActivatedRoute,
    private router: Router,private el:ElementRef) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
     }
  contentId : string;
  productCode : string;
  specialization : string;
  subproduct : string;
  showSpinner :boolean = true;
  ngOnInit(): void {
    this.contentId = this.route.snapshot.paramMap.get('contentId');
    this.productCode = this.route.snapshot.paramMap.get('productCode');
    this.specialization = this.route.snapshot.paramMap.get('specialization');
    this.subproduct = this.route.snapshot.paramMap.get('subproduct');
    this.getContent(this.productCode,this.contentId);
  }
  getContent(productCode,contentId) {
    this.service.getMedicalEmergencyDetailsByContentId(productCode,contentId).subscribe((data :MonographMedicalContent)=> {
      this.monograph = data
      this.showSpinner = false;
      document.getElementById("quicklink").innerHTML = JSON.parse(JSON.stringify( this.monograph.quickLinks));
      this.addScrollEvent();
      this.getBreadCrump();
      document.getElementById("content").innerHTML = this.monograph.bobyHtml;
      document.getElementById("spinner").style.display = 'none';
    },
      error => {
        console.log(error)
      });
  }
addScrollEvent(){
  var list : HTMLCollection=  document.getElementById("quicklink").getElementsByTagName('a');
  for(let i=0;i<list.length;i++){
    let href= list[i].getAttribute('href');
    href = href.split('#').at(1);
   list[i].setAttribute("href","javascript:void(0)");
    list[i]?.addEventListener('click', function handleClick(event) {
      const element = document.getElementsByName(href)[0];
      setTimeout(() => {}, 0);
      element.scrollIntoView({ behavior: "smooth" });
    });
  }
}
getBreadCrump(){
  let breadcrumbstr ="<a href='/home'>Home</a>";
  let producthref = "/"+this.productCode;
  let subproducthref = producthref;
  if(this.productCode!=null){
    let productTitle = this.productCode;
    if(this.subproduct!=null){
      subproducthref = producthref +"/"+this.subproduct;
      productTitle = productTitle +"-"+this.subproduct;
    }
    breadcrumbstr = breadcrumbstr + " > <span><a href='"+subproducthref+"'>"+productTitle+"</a></span>"
  }
  if(this.specialization!=null){
    let specializationhref = subproducthref +"/"+this.specialization;
    breadcrumbstr = breadcrumbstr + " > <span><a href='"+specializationhref+"'>"+this.specialization+"</a> </span>"
  }
  breadcrumbstr = breadcrumbstr + " > <span><a>"+this.monograph.pageTitle+"</a></span>";
  document.getElementById("breadCrumb").innerHTML = breadcrumbstr;
}
}
