import { Injectable } from '@angular/core';
import { Monograph } from 'src/app/models/product.model';

@Injectable({
  providedIn: 'root'
})
export class CriticalCareService {
  private monographs: Monograph[];
  private showMonographs = false;
  constructor() { }
  setMonographs(monographs: Monograph[]) {
    this.monographs = monographs;
  }
  getMonographs(): Monograph[] {
    return this.monographs;
  }
  hideMonographs(hide: boolean) {
    this.showMonographs = !hide;
  }
  displayMonographs(): boolean {
    return this.showMonographs;
  }
}
