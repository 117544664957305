import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MedicalEmergencyComponent } from './component/medical-emergency/medical-emergency.component';
import { CriticalCareComponent } from './component/critical-care/critical-care.component';
import { DrugsComponent } from './component/drugs/drugs.component';
import { LiteratureComponent } from './component/literature/literature.component';
import { LoginComponent} from './component/login/login.component';
import { CalculatorsComponent } from './component/calculators/calculators.component';
import { DrugInteractionComponent } from './component/drug-interaction/drug-interaction.component';
import { ProfileComponent } from './component/profile/profile.component';
import { MonographComponent } from './component/monograph/monograph.component';
import { SupportComponent } from './component/support/support.component';
import { GlobalConstants } from './common/global-constants';
import { AuthGuard } from './services/auth-guard/auth.guard';
const routes: Routes = [
  {path:GlobalConstants.EMERGENCY_MEDICINE_PAGE+'/:careType',component:MedicalEmergencyComponent,canActivate:[AuthGuard]},
  {path:GlobalConstants.EMERGENCY_MEDICINE_PAGE+'/:careType/:specailzation',component:MedicalEmergencyComponent,canActivate:[AuthGuard]},
  {path:'critical-care',component:CriticalCareComponent,canActivate:[AuthGuard]},
  {path:'critical-care/:specailzation',component:CriticalCareComponent,canActivate:[AuthGuard]},
  {path:'drugs',component:DrugsComponent,canActivate:[AuthGuard]},
  {path:'drugs/:specailzation',component:DrugsComponent,canActivate:[AuthGuard]},
  {path:'literature',component:LiteratureComponent,canActivate:[AuthGuard]},
  {path: 'login', component:LoginComponent},
  {path:'calculators',component:CalculatorsComponent,canActivate:[AuthGuard]},
  {path:'drug-interaction',component:DrugInteractionComponent,canActivate:[AuthGuard]},
  {path:'profile',component:ProfileComponent,canActivate:[AuthGuard]},
  {path:'product/:productCode/specialization/:specialization/monograph/:contentId',component:MonographComponent,canActivate:[AuthGuard]},
  {path:'product/:productCode/subproduct/:subproduct/specialization/:specialization/monograph/:contentId',component:MonographComponent,canActivate:[AuthGuard]},
  {path:'monograph/:contentId',component:MonographComponent,canActivate:[AuthGuard]},
  {path:'support',component:SupportComponent,canActivate:[AuthGuard]},
  {path:'home',redirectTo: GlobalConstants.EMERGENCY_MEDICINE_PAGE+'/adult'},
  {path:'', pathMatch: 'full', redirectTo: '/login'}
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }