export class BaseProduct{
    id: number;
    name:string;
}
export class Product extends BaseProduct{
    code:string;
}
export class Specialization extends BaseProduct{
    monographs: Monograph[];
}
export class Monograph extends BaseProduct{
    contentId : string;
}
export class MonographMedicalContent{
    productCode : string;
    code : string;
    contentId : string;
    pageTitle : string;
    quickLinks : string;
    bobyHtml : string;
}