<div class="col-lg-4 marginTop20 loginSection">
    <h1 class="welcomeText">Welcome Back</h1>
    <p class="subText">Start managing your patient faster and better</p>
    <form [formGroup]="loginForm" (ngSubmit)="SaveLoginDetails()" class="loginForm">
        <mat-card>
            <mat-card-content class="loginContent">
                <div formGroupName="loginInfo">
                    <div class="form-group">
                        <mat-form-field appearance="outline" class="width100 matField">
                            <span class="loginIcon"><mat-icon> email</mat-icon></span><input matInput formControlName="username" required placeholder="you@example.com"/>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="outline" class="width100 matField">
                            <span class="loginLockIcon"><mat-icon>lock</mat-icon></span><input type="password" matInput formControlName="password" required placeholder="password here" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="clearfix"></div>
                <!--<a href="" class="forgotLink">Forgot Password</a>-->
                <button [disabled]="!loginForm.valid" mat-raised-button color="primary" class="loginBtn">Login</button>
            </mat-card-content>
        </mat-card>
    </form>
</div>