import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../app/material.module';
import { MenuComponent } from '../app/component/menu/menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app.routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpService } from './services/http.service';
import { CriticalCareComponent } from './component/critical-care/critical-care.component';
import { LiteratureComponent } from './component/literature/literature.component';
import { CalculatorsComponent } from './component/calculators/calculators.component';
import { DrugInteractionComponent } from './component/drug-interaction/drug-interaction.component';
import { ProfileComponent } from './component/profile/profile.component';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MonographComponent } from './component/monograph/monograph.component';
import { DrugsComponent } from './component/drugs/drugs.component';
import { SupportComponent } from './component/support/support.component';
import { AuthGuard } from './services/auth-guard/auth.guard';
import { AuthService } from './services/auth-service/auth.service';
import { AuthInterceptor } from './services/auth-interceptor/auth.interceptor';
import { JwtModule } from '@auth0/angular-jwt';
import { LoaderComponent } from './component/loader/loader.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgFor, CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MedicalEmergencyComponent } from './component/medical-emergency/medical-emergency.component';
@NgModule({
  declarations: [
    AppComponent,MedicalEmergencyComponent, MenuComponent, CriticalCareComponent, LiteratureComponent, LoginComponent, CalculatorsComponent, ProfileComponent, MonographComponent,DrugsComponent, DrugInteractionComponent, SupportComponent, LoaderComponent ],
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    NgFor,
    MatGridListModule,
    ScrollingModule,
    MatButtonToggleModule,
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatExpansionModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [RegExp('.*')],
      },
    })
  ],
  providers: [HttpService,AuthGuard,AuthService,{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  schemas :[CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
function tokenGetter(){
  return localStorage.getItem("token");
}

