import { Product } from "../models/product.model";

export class GlobalConstants {
    public static createNewADR: string = "Create a new ADR entry";
    public ADULT: string = "Adult";
    public PEDIATRIC: string = "Pediatric";
    public SPECIALIZATION_HEADER: string = "Therapeutic Areas";
    public EM_ADULT_HEADER: string = "EM Specialties - Adult";
    public EM_PEDIATRIC_HEADER: string = "EM Specialties - Pediatric";
    public CRITICAL_CARE_HEADER: string = "Critical Care Nursing";
    public DRUGS_HEADER: string = "Drug Categories";
    public DINFO: string = "Drug Info";
    public DINTERACTION: string = "Drug Interaction";
    public static EMERGENCY_MEDICIN_ADULT: string = "emergency-medicine-adult";
    public static EMERGENCY_MEDICINE_PEDIATRIC: string = "emergency-medicine-pediatric";
    public static CRITICAL_CARE: string = "critical-care";
    public static DRUGS: string = "drugs";
    public static DRUGS_INTARACTIONS: string = "drugs-interactions";
    public static EMERGENCY_MEDICINE_PAGE="emergency-medicine";
    public static CRITICAL_CARE_PAGE="critical-care";
    public static DRUG_PAGE="drugs";
    public static DRUG_INTERACTION_PAGE="drug-interaction";
    public static TOKEN="token";
    public static REFRESH_TOKEN="refreshToken";
    public static productToIdMapping: Map<String, Product> = new Map([
        [GlobalConstants.EMERGENCY_MEDICIN_ADULT, GlobalConstants.getProductObject(1, 'PLED')],
        [GlobalConstants.CRITICAL_CARE, GlobalConstants.getProductObject(2, 'PLCC')],
        [GlobalConstants.EMERGENCY_MEDICINE_PEDIATRIC, GlobalConstants.getProductObject(3, 'EMPD')],
        [GlobalConstants.DRUGS, GlobalConstants.getProductObject(4, 'DRUG')],
    ])
    public static productToIdMapping1: Map<String, Product> = new Map([
        [GlobalConstants.EMERGENCY_MEDICIN_ADULT, new Product()],
    ])
    static getProductObject(id: number, code: string): Product {
        let product: Product = new Product();
        product.id = id;
        product.code = code;
        return product;
    }
}
