<!--<iframe src="https://qxmd.com/calculate/calculator_42/framingham-chf-criteria?embed=1" style="border:0; box-shadow:none; min-height:720px; min-width:310px; width:100%; max-width:720px; overflow:hidden;"></iframe>-->
<div class="container">
    <br/><br/><br/><br/>
    <div class="two-panes-wrap text-content-wrap">
        <div class="left-pane alhabets">
            <div class="alphabetDesease">
                <!--<ul>
                    <li *ngFor="let lt of literature">
                        <a class="active" (click)="getContent(lt)">
                            {{lt.specialty}}
                        </a>
                    </li>
                </ul>-->
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let lt of literature" hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-title (click)="getContent(lt)">
                                {{lt.specialty}}
                            </mat-panel-title>
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </mat-expansion-panel-header>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
        <div class="right-pane disease-list-wrapper" id="content">
        </div>
    </div>  
</div>
