<div class="container">
    <div class="header-style">
        {{monographName}}
    </div>
    <div>
        <ul class="ul-sytle" *ngIf="showSpecializations">
            <li class="calCatName" *ngFor="let cal of menu">
                <p>
                    <a class="active" (click)="getMonographsBySpecialization(cal)">
                        {{cal.name}}
                    </a>
                </p>
            </li>
        </ul>
    </div>

    <ul class="ul-sytle-spl" *ngIf="!showSpecializations">
        <li class="li-style" *ngFor="let cal of dService.getMonographs()">
            <p>
                <a class="active" (click)="getMonographPage(cal)">
                    {{cal.name}}
                </a>
            </p>
        </li>
    </ul>
</div>
<app-loader *ngIf="showSpinner"></app-loader>