import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';
import { Monograph } from 'src/app/models/product.model';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  constructor(private service: HttpService, private router: Router,private authService: AuthService) {
  }
  isAuthenticated =  this.authService.isTokenValid();
  appConst: GlobalConstants = new GlobalConstants();
  searchFormControl = new FormControl('');
  optionFormControl = new FormControl('');
  searchList: Monograph[];
  displaySearch: boolean = false;
  searchDesease() {
    var text = this.searchFormControl.value;
    this.service.getCodesForMedicalEmergency(text).subscribe((data: Monograph[]) => {
      this.searchList = data;
    },
      error => {
        console.log(error)
      });
  }
  displayFn(content: Monograph): string {
    return content && content.name ? content.name : '';
  }
  changeContent(content: Monograph) {
    this.displaySearch = true;
    this.getContent(content.contentId);
  }
  logout(){
    this.authService.deleteToken();
    this.router.navigate(['/login']); 
  }
  getContent(contentId) {
    this.router.navigate(['/monograph/' + contentId]);
    /*this.service.getMedicalEmergencyDetailsByContentId(contentId).subscribe(data => {
      this.content = JSON.parse(JSON.stringify(data["BodyHTML"]));
      document.getElementById("quicklink").innerHTML = JSON.parse(JSON.stringify(data["QuickLinks"]));
      document.getElementById("content").innerHTML = this.content;
    },
      error => {
        console.log(error)
      });*/
  }
}
