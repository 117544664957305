import { Component } from '@angular/core';
import { Calculator, CalculatorSubcategory } from 'src/app/models/calculator.model';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-calculators',
  templateUrl: './calculators.component.html',
  styleUrls: ['./calculators.component.css']
})
export class CalculatorsComponent {
  calculators: Calculator[];
  constructor(private service: HttpService) { }
  ngOnInit(): void {
    this.service.getCalculatorContent().subscribe((res: Calculator[]) => {
      this.calculators = res;
      document.getElementById("content").innerHTML = this.calculators[0].subCategory[0].embeddableLink;
      document.getElementById("myframe").style.color = 'red';
      document.getElementById("myframe").style.fontSize = '16px';
    });
  }
  getContent(calculator: CalculatorSubcategory) {
    document.getElementById("content").innerHTML = calculator.embeddableLink;
  }
}
