import { Component,OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginDetails } from 'src/app/models/patient.model'; 
import { HttpService } from 'src/app/services/http.service';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginDetails : LoginDetails;
  loginForm: FormGroup;
  helper = new JwtHelperService();
  constructor(private fb: FormBuilder, private router: Router,private AuthService: AuthService ) {
    this.loginDetails = new LoginDetails();
   this.createForm();
 }
 createForm() {
  this.loginForm = this.fb.group({
    loginInfo : this.fb.group({
      username: ["",[ Validators.required] ],
      password: ["", Validators.required ]
    })
  });
 }
  ngOnInit(): void {
    if(this.AuthService.isAuthenticated()){
      this.router.navigate(['/'+GlobalConstants.EMERGENCY_MEDICINE_PAGE+'/adult']);
    }
  }

  async SaveLoginDetails(){
  //console.log(this.loginForm.value);
    const username = this.loginForm.get("loginInfo").get("username").value;
    const password = this.loginForm.get("loginInfo").get("password").value;
    await this.AuthService.login(username, password);
    if(localStorage.getItem(GlobalConstants.TOKEN)){
      this.AuthService.isLoggedIn = true;
      this.router.navigate(['/'+GlobalConstants.EMERGENCY_MEDICINE_PAGE+'/adult']);
    }
  }
}
