<div class="container">
    <div class="header-style">
        <div *ngIf="showBackBtn" style="width: 10%; float:left;">
            <button mat-button color="primary" (click)="goBack()" class="header-btn">
                < Back</button>
        </div>
        <div style="width: 90%; float:right">
            {{monographName}}
        </div>
    </div>
<div class="col-lg-12 col-xs-12">
    <ul class="ul-sytle" *ngIf="showSpecializations">
        <li class="calCatName" *ngFor="let cal of menu" >
            <p>
                <a class="active" (click)="getMonographsBySpecialization(cal)">
                    {{cal.name}}
                </a>
            </p>
        </li>
    </ul>
</div>

<ul class="ul-sytle-spl" *ngIf="!showSpecializations">
    <li class="li-style" *ngFor="let cal of mServices.getMonographs()" >
        <p>
            <a class="active" (click)="getMonographPage(cal)">
                {{cal.name}}
            </a>
        </p>
    </li>
</ul>
</div>
<app-loader *ngIf="showSpinner"></app-loader>
