import { Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgFor } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GlobalConstants } from 'src/app/common/global-constants';
import { HttpService } from 'src/app/services/http.service';
import { MatGridListModule } from '@angular/material/grid-list';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { Monograph, Specialization } from 'src/app/models/product.model';
import { MedicalEmergencyService } from 'src/app/services/medical-emergency-service/medical-emergency.service';
import { Location } from '@angular/common';
import { CommonService } from 'src/app/services/common-services/common.service';
import { LoaderComponent } from '../loader/loader.component';
@Component({
  selector: 'app-medical-emergency',
  templateUrl: './medical-emergency.component.html',
  styleUrls: ['./medical-emergency.component.css']
})
export class MedicalEmergencyComponent {
  myControl = new FormControl('');
  content: any;
  filterData: Monograph[];
  searchList: Monograph[];
  adult: boolean = true;
  menu: Specialization[];
  showSpecializations = true;
  showBackBtn = false;
  constructor(private service: HttpService, private route: ActivatedRoute,
    private router: Router, public mServices: MedicalEmergencyService, private location: Location,public commonService:CommonService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }
  appConst: any = GlobalConstants;
  selectedAlphbetData: any;
  careType: string;
  medicalEmergencyMap = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
  searchFormControl = new FormControl('');
  optionFormControl = new FormControl('');
  appConstants: GlobalConstants = new GlobalConstants();
  careTypeURL: string;
  specialization: string;
  monographName:  string = this.appConstants.SPECIALIZATION_HEADER;
  showSpinner :boolean = true;
  ngOnInit(): void {
    this.careType = this.route.snapshot.paramMap.get('careType');
    this.specialization = this.route.snapshot.paramMap.get('specailzation');
    this.show(this.careType)
    var id;
    if (this.careType == "adult" || this.careType == "Adult") {
      id = GlobalConstants.productToIdMapping.get(GlobalConstants.EMERGENCY_MEDICIN_ADULT).id
      this.monographName = this.appConstants.EM_ADULT_HEADER;
    } else {
      id = GlobalConstants.productToIdMapping.get(GlobalConstants.EMERGENCY_MEDICINE_PEDIATRIC).id;
      this.monographName = this.appConstants.EM_PEDIATRIC_HEADER;
    }
    this.service.getProductSpecializationsByProductId(id).subscribe((data: Specialization[]) => {
      this.menu = data;
      this.showSpinner = false;
      /* below logic for browser refresh*/
      if (this.specialization != null) {
        var str = new String(this.specialization);
        var str1 = str.split("-").join(" ").toLowerCase();
        var filteredData = data.filter(e => e.name.toLocaleLowerCase() == str1);
        if (filteredData.length > 0) {
          this.mServices.setMonographs(filteredData[0].monographs);
          this.mServices.hideMonographs(false);
          this.showSpecializations = !this.mServices.displayMonographs();
          this.monographName = filteredData[0].name;
          this.showBackBtn = true;
        }
      }
    });
    /*this.medicalEmergencyMap
    this.getInitalContent(this.medicalEmergencyMap[0]);*/
  }
  filterResults(text: string) {
    this.service.getCodesForMedicalEmergency(text).subscribe((data: Monograph[]) => {
      this.selectedAlphbetData = data;
    },
      error => {
        console.log(error)
      });
  }
  getContentBySpecilization(contentId) {

  }
  
  displayFn(content: Monograph): string {
    return content && content.name ? content.name : '';
  }
  show(careType: string) {
    if (careType == this.appConstants.ADULT) {
      this.adult = true;
    }
    else if (careType == this.appConstants.PEDIATRIC) {
      this.adult = false;
    }

  }
  getMonographsBySpecialization(specailzation: Specialization) {
    this.mServices.setMonographs(specailzation.monographs);
    this.mServices.hideMonographs(false);
    var str = new String(specailzation.name);
    this.showSpecializations = this.mServices.displayMonographs();
    var str1 = str.split(" ").join("-").toLowerCase();
    this.router.navigate([GlobalConstants.EMERGENCY_MEDICINE_PAGE+'/' + this.careType + '/' + str1]);
    this.showSpinner = false;
  }
  getMonographPage(monograph:Monograph){
    this.commonService.getMonographPageByProductIdAndSubproduct(GlobalConstants.EMERGENCY_MEDICINE_PAGE,this.careType,this.specialization,monograph);
  }
  goBack() {
    this.location.back();
    if(window.location.pathname="/"+GlobalConstants.EMERGENCY_MEDICINE_PAGE+"/adult"){
      document.getElementById("backBtn").style.display = 'none';
    }
  }
}
