import { Component } from '@angular/core';

@Component({
  selector: 'app-drug-interaction',
  templateUrl: './drug-interaction.component.html',
  styleUrls: ['./drug-interaction.component.css']
})
export class DrugInteractionComponent {

}
