export class LoginRequest {
    username: string;
    password: string;
    token: string;
}
export class ResponseMessage{
    message: string;
}
export class SignUpRequest{
    email: string;
    fname: string;
    lname: string;
    password: string;
}
export class UserTokenResponse{
    token: string;
    refreshToken: string;
}