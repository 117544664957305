import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PatientDetails, Title } from '../models/patient.model';
import { Literature } from '../models/literature.model';
import { Calculator } from '../models/calculator.model';
import { Monograph, MonographMedicalContent, Specialization } from '../models/product.model';
import { environment } from 'src/environments/environment';
import { LoginRequest, ResponseMessage, UserTokenResponse } from '../models/auth.model';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  url : String = environment.url;
  productUrl = environment.url + "/product";
  authUrl = environment.url + "/auth";
  constructor(private http: HttpClient) { 
   
  }
  getData() {
    return this.http.get<Title>('http://lifearchealth.com:8080/' +'ping');
  }
  getPatients() {
    return this.http.get<PatientDetails[]>(this.url +'hospital_id/HOS_01/get_patients');
  }
  getProductUrl(url:String){
    return this.productUrl + url;
  }
  getAuthUrl(url:String){
    return this.authUrl + url;
  }
  getCodesForMedicalEmergency(search){
    const url =  this.getProductUrl("/search/"+search);
    return this.http.get<Monograph[]>(url, { responseType: "json" })
  }
  getMedicalEmergencyDetailsByContentId(productCode ,contentId){
    id: String;
    const url = this.getProductUrl("/"+productCode+"/content_id/"+contentId);
    return this.http
    .get<MonographMedicalContent>(url, { responseType: "json" })
  }
  getLiteratureContent(){
   let url: string = "/assets/literature_collections.json";
    return this.http.get<Literature[]>(url, { responseType: "json" })
  }
  getCalculatorContent(){
    let url: string = "/assets/calculator_collection.json";
     return this.http.get<Calculator[]>(url, { responseType: "json" })
   }
   getProductSpecializationsByProductId(id:number){
    let url: string = this.getProductUrl("/"+id+"/specialization");
     return this.http.get<Specialization[]>(url, { responseType: "json" })
   }
   getMonographsBySpecialization(id:string,specialization:string){
    let url: string = this.getProductUrl("/"+id+"/specialization"+specialization);
     return this.http.get<Monograph[]>(url, { responseType: "json" })
   }
   getMonographsBySpecializationId(id:number,splid:number){
    let url: string = this.getProductUrl("/"+id+"/specialization/"+splid+"/monograph");
     return this.http.get<Monograph[]>(url, { responseType: "json" })
   }
   getMonographsByProductId(id:number){
    let url: string = this.getProductUrl("/"+id+"/monograph");
     return this.http.get<Monograph[]>(url, { responseType: "json" })
   }
   sign(request : LoginRequest){
    let url: string = this.getAuthUrl("/token");
    return this.http.post<UserTokenResponse>(url,request, { responseType: "json" })
   }
   signout(request : LoginRequest){
    let url: string = this.getAuthUrl("/signout");
    return this.http.delete<ResponseMessage>(url, { responseType: "json" })
   }
}
