<div class="container-fluid" *ngIf="isAuthenticated">
  <div class="row">
    <p class="menu-style col-lg-12 col-xs-12">
      <mat-toolbar color="primary" class="mobileMatTool">
        <div class="col-xs-2 navbar-header hidden-lg">
          <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
        </div>
        <div class="col-lg-2 col-xs-8 text-center">
          <a href="/emergency-medicine/adult"><img src="../assets/images/life-arc.png" class="img-style"/></a> 
        </div>
        <div class="col-lg-12 col-xs-12 navbar-collapse collapse" id="myNavbar">
        <ul class="header-menu col-lg-10 menu-items lucidaFont nav navbar-nav">
          <li>
            <span [matMenuTriggerFor]="menu">Emergency Medicine <mat-icon class="downArrow">keyboard_arrow_down</mat-icon></span>
            <mat-menu #menu="matMenu">
              <button  class="lucidaFont" [routerLink]="['/emergency-medicine', appConst.ADULT]" mat-menu-item>{{appConst.ADULT}}</button>
              <button   class="lucidaFont" [routerLink]="['/emergency-medicine', appConst.PEDIATRIC]" mat-menu-item>{{appConst.PEDIATRIC}}</button>
            </mat-menu>
          </li>
          <li>
            <span routerLink="critical-care">Critical Care</span>
          </li>
          <li>
            <span [matMenuTriggerFor]="drug_menu">Drugs <mat-icon class="downArrow">keyboard_arrow_down</mat-icon></span>
            <mat-menu #drug_menu="matMenu">
              <button class="lucidaFont" [routerLink]="['/drugs']" mat-menu-item>{{appConst.DINFO}}</button>
              <button class="lucidaFont" [routerLink]="['/drug-interaction']" mat-menu-item>{{appConst.DINTERACTION}}</button>
            </mat-menu>
          </li>
          <li>
            <span routerLink="literature">Literature</span>
          </li>
          <li>
            <span routerLink="calculators">Calculators</span>
          </li>
          <li>
            <!--<form class="search-form">
              <mat-form-field class="search-Style">
                  <mat-label class="labelText">Search</mat-label>
                  <input type="text" class = "search-input" aria-label="Number" matInput [formControl]="searchFormControl" [matAutocomplete]="auto" (keyup)="searchDesease()">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" >
                      <mat-option *ngFor="let option of searchList; index as i" [value]="option" (onSelectionChange)="changeContent(option)">
                          {{option.name}}
                      </mat-option>
                  </mat-autocomplete>
              </mat-form-field>
          </form>-->
          <!--<li>
            <button mat-icon-button class="example-icon" [matMenuTriggerFor]="menu">Emergency medicine</button>
            <mat-menu #menu="matMenu">
              <button [routerLink]="['/emergency-medicine', appConst.ADULT]" mat-menu-item>{{appConst.ADULT}}</button>
              <button [routerLink]="['/emergency-medicine', appConst.PEDIATRIC]" mat-menu-item>{{appConst.PEDIATRIC}}</button>
            </mat-menu></li>
          <li><span class="example-spacer"></span>
            <button routerLink="critical-care" mat-icon-button class="example-icon"
              aria-label="Example icon-button with menu icon">
              <span>Critical Care</span>
            </button>
          </li>
          <li><span class="example-spacer"></span>
            <button routerLink="drugs" mat-icon-button class="example-icon"
              aria-label="Example icon-button with menu icon">
              <span>Drugs</span>
            </button>
          </li>
          <li><span class="example-spacer"></span>
            <button routerLink="literature" mat-icon-button class="example-icon"
              aria-label="Example icon-button with menu icon">
              <span>Literature</span>
            </button>
          </li>
          <li><span class="example-spacer"></span>
            <button routerLink="calculators" mat-icon-button class="example-icon"
              aria-label="Example icon-button with menu icon">
              <span>Calculators</span>
            </button>
          </li>
          <li><span class="example-spacer"></span>
            <button routerLink="profile" mat-icon-button class="example-icon favorite-icon"
              aria-label="Example icon-button with heart icon">
              <mat-icon class="profileIcon">account_circle</mat-icon>
            </button>
          </li>-->
        </ul>
      </div>
      <div>
        <ul class="header-menu-right pull-left">
          <li class="mobileSearch">
          <form class="search-form">
            <input type="text"
                   placeholder="Search drug, disease, procedure etc "
                   [formControl]="searchFormControl"
                   [matAutocomplete]="auto"
                   class="search-input" (keyup)="searchDesease()">
                   <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" >
                    <mat-option  *ngFor="let option of searchList; index as i" [value]="option" (onSelectionChange)="changeContent(option)">
                        <span class="search-option">{{option.name}}</span>
                    </mat-option>
                </mat-autocomplete>
          </form>
          <div class="searchIcon">
            <mat-icon class="iconImg">search</mat-icon>
          </div>
          </li>
          <li class="mobileProfileIcon">
            <span [matMenuTriggerFor]="menu" [matMenuTriggerFor]="settings">
              <!-- <mat-icon [matMenuTriggerFor]="menu" [matMenuTriggerFor]="settings" class="profileIcon">account_circle</mat-icon> -->
              <img src="../assets/images/user-profile.png" class="" width="25px"/>
            </span>
            <mat-menu #settings="matMenu">
              <button  class="lucidaFont" [routerLink]="['/support']" mat-menu-item>Support</button>
              <button   class="lucidaFont" (click)="logout()"  mat-menu-item>Logout</button>
            </mat-menu>
          </li>
        </ul>
      </div>
      </mat-toolbar>
    </p>
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>