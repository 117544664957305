import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Monograph } from 'src/app/models/product.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private router: Router) { }
  getMonographPage(monograph: Monograph) {
    this.router.navigate(['/monograph/' + monograph.contentId]);
  }
  getMonographPageByProductIdAndSpecialization(productId:string,splId:string,monograph: Monograph) {
    let str = "/product/"+productId+"/specialization/"+splId+"/monograph/"+monograph.contentId;
    this.router.navigate([str]);
  }
  getMonographPageByProductIdAndSubproduct(productId:string,subproduct: string,splId:string,monograph: Monograph) {
    let str = "/product/"+productId+"/subproduct/"+subproduct+"/specialization/"+splId+"/monograph/"+monograph.contentId;
    this.router.navigate([str]);
  }
}
