import { EmailValidator } from "@angular/forms";

export class Title {
    title: string;
}
export class PatientDetails {
    id: string;
    patientInfo: PatientInfo;
    reporterInfo: ReporterInfo;
    eventInfo: EventInfo;
    drugInfo: DrugInfo;
    medicalHistory: string;
    additionalInfo: string;
    pregnancyInfo: PregnancyInfo;
    attachmentsInfo: AttachmentsInfo;
    createdDate:string;
    updatedDate:string;
}
export class PatientInfo {
      firstName:string;
      age:number;
      lastName:string;
      gender:string;
      dateOfBirth:string;
      typeOfJoining:string;
}
export class ReporterInfo {
    reporterName:string;
    reporterContact:string;
    hcp:string;
    regionalCenter:string;
}
export class EventInfo {
    verbattim:string;
    code:string;
    startDate:string;
    endDate:string;
    outCome:string;
    deChallenge:string;
    reChallenge:string;
    seriousness:boolean;
    criteria:string;
}
export class DrugInfo {
    genericName:string;
    dosage:string;
    routeOfAdministration:string;
    manufactureInfo:string;
    batchNo:string;
}
export class PregnancyInfo {
    iSPregnant:string;
    trimester:string;
    additionalInfo:string;
}
export class AttachmentsInfo {
    title:string;
    file:string;
}
export class LoginDetails {
    username:EmailValidator;
    password:string;
}
