import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { LoginRequest, UserTokenResponse } from 'src/app/models/auth.model';
import { HttpService } from '../http.service';
import { GlobalConstants } from 'src/app/common/global-constants';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { formatDate } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;
  constructor(private httpService: HttpService,private jwtHelper:JwtHelperService) { }
  isAuthenticated() {
    return this.isTokenValid();
  }
  getToken(): string {
    return localStorage.getItem(GlobalConstants.TOKEN);
  }
  deleteToken() {
    return localStorage.removeItem(GlobalConstants.TOKEN);
  }
  decodeToken(): JwtPayload {
    let token = this.getToken();
    if (token != null) {
      return jwtDecode(token);
    }
    return null;
  }
  getUserName() {
    //const helper = new JwtHelperService();
    let decodedToken: JwtPayload = this.decodeToken();
    return decodedToken['fname'] + ' ' + decodedToken['lname'];
  }
  isTokenValid(): boolean {
    let token = this.getToken();
    let bool = this.jwtHelper.isTokenExpired(token);
    return !bool;
    /*let token = this.decodeToken();
    if (token != null) {
      var date = new Date(token.exp);
     let curr = (Math.floor((new Date).getTime() / 1000));
      if (curr < token.exp) {
        return true;
      }
    }
    return false;*/
  }
  async login(username: string, password: string): Promise<UserTokenResponse> {
    let loginrequest = new LoginRequest();
    loginrequest.username = username;
    loginrequest.password = password;
    return new Promise((resolve, reject) => {
      this.httpService.sign(loginrequest).subscribe((e: UserTokenResponse) => {
        localStorage.setItem("token", e.token);
        localStorage.setItem("refreshtoken", e.refreshToken);
        resolve(e);
      }, error => {
        reject(error)
      }
      )
    },
    )
  }
}
