<div class="container" [hidden]="showSpinner">
        <div class="top-style">
            <div class="col-lg-12 paddingBottom20 breadCrumb">
                <span id="breadCrumb"></span> 
            </div>
            <div class ="col-lg-4 left-pane"> 
                <div id="quicklink" class="quickContent"></div> 
            </div>
            <div class ="col-lg-8 right-pane">
                <div>
                    <div id="content"></div>
                </div>
            </div>
            
        </div>
    </div>
    <app-loader *ngIf="showSpinner"></app-loader>