<div class="container">
    <br /><br /><br /><br />
    

    <div class="two-panes-wrap text-content-wrap">
        <div class="left-pane alhabets">
            <div class="alphabetDesease">
                <mat-accordion>
                <mat-expansion-panel *ngFor="let cal of calculators">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{cal.category}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngFor="let sub of cal.subCategory">
                        <a class="active" (click)="getContent(sub)">
                            {{sub.title}}<br />
                            {{sub.description}}
                        </a>
                    </p>
                </mat-expansion-panel>
            </mat-accordion>
                <!-- <ul>
                    <li *ngFor="let cal of calculators"><b> {{cal.category}}</b>
                        <ul class="marginTop10">
                            <li class="calCatName" *ngFor="let sub of cal.subCategory">
                                <a class="active" (click)="getContent(sub)">
                                    {{sub.title}}<br />
                                    {{sub.description}}
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul> -->
            </div>
        </div>
        <div class="right-pane disease-list-wrapper" id="content">
        </div>
    </div>
</div>