import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Monograph, Specialization } from 'src/app/models/product.model';
import { CommonService } from 'src/app/services/common-services/common.service';
import { CriticalCareService } from 'src/app/services/critical-care-service/critical-care.service';
import { HttpService } from 'src/app/services/http.service';
import { Location } from '@angular/common';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-critical-care',
  templateUrl: './critical-care.component.html',
  styleUrls: ['./critical-care.component.css']
})
export class CriticalCareComponent {
  adult: boolean = true;
  menu: Specialization[];
  showSpecializations = true;
  specialization: string;
  appConstants: GlobalConstants = new GlobalConstants();
  monographName:  string = this.appConstants.CRITICAL_CARE_HEADER;
  showSpinner = true;
  constructor(private service: HttpService,public cService:CriticalCareService,private router: Router, private route: ActivatedRoute,public commonService:CommonService, private location: Location) { }
  ngOnInit(): void {
    this.specialization = this.route.snapshot.paramMap.get('specailzation');
    this.service.getProductSpecializationsByProductId(GlobalConstants.productToIdMapping.get(GlobalConstants.CRITICAL_CARE).id).subscribe((data:Specialization[])=>{
     this.menu = data;
     this.showSpinner = false;
      /* below logic for browser refresh*/
      if (this.specialization != null) {
        var str = new String(this.specialization);
        var str1 = str.split("-").join(" ").toLowerCase();
        var filteredData = data.filter(e => e.name.toLocaleLowerCase() == str1);
        if (filteredData.length > 0) {
          this.cService.setMonographs(filteredData[0].monographs);
          this.cService.hideMonographs(false);
          this.showSpecializations = !this.cService.displayMonographs();
          this.monographName = filteredData[0].name;
        }
      }
    });
  }
  getMonographsBySpecialization(specailzation: Specialization) {
    this.cService.setMonographs(specailzation.monographs);
    this.cService.hideMonographs(false);
    var str = new String(specailzation.name);
    this.showSpecializations = this.cService.displayMonographs();
    var str1 = str.split(" ").join("-").toLowerCase();
    this.router.navigate(['critical-care/' + str1]);
  }
  getMonographPage(monograph:Monograph){
    this.commonService.getMonographPageByProductIdAndSpecialization(GlobalConstants.CRITICAL_CARE_PAGE,this.specialization,monograph);
  }
  goBack() {
    this.location.back();
  }
}
