import { Component } from '@angular/core';
import { Literature } from 'src/app/models/literature.model';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-literature',
  templateUrl: './literature.component.html',
  styleUrls: ['./literature.component.css']
})
export class LiteratureComponent {
  literature: Literature[];
  constructor(private service: HttpService) { }
  ngOnInit(): void {
    this.service.getLiteratureContent().subscribe((res: Literature[]) => {
      this.literature = res;
      document.getElementById("content").innerHTML = this.literature[0].embeddableLink;
      document.getElementById("myframe").style.color = 'red';
      document.getElementById("myframe").style.fontSize = '16px';
    });
  }
  getContent(lt: Literature) {
    document.getElementById("content").innerHTML = lt.embeddableLink;
  }
}
