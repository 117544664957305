import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';
import { Monograph, Specialization } from 'src/app/models/product.model';
import { CommonService } from 'src/app/services/common-services/common.service';
import { DrugsService } from 'src/app/services/drugs-service/drugs.service';
import { HttpService } from 'src/app/services/http.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-drugs',
  templateUrl: './drugs.component.html',
  styleUrls: ['./drugs.component.css']
})
export class DrugsComponent {
  adult: boolean = true;
  menu: Specialization[];
  showSpecializations = true;
  specialization: string;
  appConstants: GlobalConstants = new GlobalConstants();
  monographName: string = this.appConstants.DRUGS_HEADER;
  showSpinner = true;
  constructor(private service: HttpService, public dService: DrugsService, private router: Router, private route: ActivatedRoute, public commonService: CommonService, private location: Location) { }
  ngOnInit(): void {
    this.specialization = this.route.snapshot.paramMap.get('specailzation');
    this.service.getProductSpecializationsByProductId(GlobalConstants.productToIdMapping.get(GlobalConstants.DRUGS).id).subscribe((data: Specialization[]) => {
      this.menu = data;
      this.showSpinner = false;
      /* below logic for browser refresh*/
      if (this.specialization != null) {
        var str = new String(this.specialization);
        var str1 = str.split("-").join(" ").toLowerCase();
        var filteredData = data.filter(e => e.name.toLocaleLowerCase() == str1);
        if (filteredData.length > 0) {
          this.dService.setMonographs(filteredData[0].monographs);
          this.dService.hideMonographs(false);
          this.showSpecializations = !this.dService.displayMonographs();
          this.monographName = filteredData[0].name;
        }
      }
    });
  }
  getMonographsBySpecialization(specailzation: Specialization) {
    this.dService.setMonographs(specailzation.monographs);
    this.dService.hideMonographs(false);
    var str = new String(specailzation.name);
    this.showSpecializations = this.dService.displayMonographs();
    var str1 = str.split(" ").join("-").toLowerCase();
    this.router.navigate(['drugs/' + str1]);
  }
  getMonographPage(monograph:Monograph){
    this.commonService.getMonographPageByProductIdAndSpecialization(GlobalConstants.DRUGS,this.specialization,monograph);
  }
  goBack() {
    this.location.back();
  }
}
